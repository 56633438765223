<template>
  <div class="home">
    <!-- 首页-头部 -->
    <div class="home-header">
      <!-- 头部-背景 -->
      <div class="header-bg">
        <vue-particles style="height:100%" v-bind="particlesConfig" />
      </div>

      <!-- 头部-Logo -->
      <img class="header-logo" src="/icons/RF_LOGO.png" alt="" />

      <!-- 头部-Slogan -->
      <slogan
        title="实时可扩展图分析平台"
        memo="利用原生并行图技术释放互联数据的力量，洞悉深层关联的商业价值"
      />

      <!-- 头部-按钮 -->
      <el-button
        type="danger"
        class="header-btn"
        @click="$router.push('/applyTrial')"
        >申请使用 <i class="el-icon-caret-right"
      /></el-button>

      <!-- 头部-标题 -->
      <div class="header-title">
        <div class="title-items">
          <div
            class="title-item"
            v-for="(item, index) in headerTitles"
            :key="index"
          >
            <p class="title-name" v-text="item.name" />
            <p class="title-memo" v-text="item.memo" />
          </div>
        </div>
      </div>
    </div>

    <!-- 首页-主体 -->
    <div class="home-content">
      <!-- 主体-产品 -->
      <product showBtn :productData="productData" @viewProduct="viewProduct" />

      <!-- 主体-产品细节 -->
      <horizontalBar
        title="图谱优势"
        subTitle="Atlas Advantage"
        :barStyle="productDetail.style"
      >
        <product-detail :productDetailData="productDetail.productDetailList" />
      </horizontalBar>

      <!-- 主体-产品应用 -->
      <horizontalBar
        grayTitle
        title="多层级应用"
        subTitle="Multilevel Application"
        :barStyle="application.style"
      >
        <product-application :applicationData="application.applicationList" />
      </horizontalBar>

      <!-- 主体-新闻动态 -->
      <horizontalBar
        grayTitle
        title="新闻动态"
        subTitle="News"
        :barStyle="news.style"
      >
        <div class="news-items">
          <news-card
            v-for="(item, index) of news.newsList"
            :key="index"
            :newsData="item"
            @viewDetail="viewDetails(item)"
          />
        </div>
      </horizontalBar>

      <!-- 主体-联系我们 -->
      <horizontalBar hiddenTitle>
        <div class="apply-contact">
          <applyContact
            title="申请获取更多方案资料"
            memo="想了解更多,请点击下方按钮来申请获取。申请审核通过后将会有专人负责与您联系。"
            btnText="申请获取"
            @acBtn="$router.push('/applyTrial')"
          />

          <applyContact
            plain
            title="联系我们"
            memo="我们为您提供个性化的咨询服务,以及全面的专业技术服务。"
            btnText="联系我们"
            @acBtn="$router.push('/aboutUs')"
          />
        </div>
      </horizontalBar>
    </div>
  </div>
</template>

<script>
import slogan from "./components/slogan.vue"; // slogan-组件
import applyContact from "./components/apply-contact.vue"; // 申请-联系我们-组件
import productApplication from "./components/product-application.vue"; // 产品多层级应用-组件
import { Throttle } from "@/utils"; // 引入防抖函数
import { getNewsList } from "@/api/news";

export default {
  components: {
    slogan,
    applyContact,
    productApplication,
  },
  data() {
    return {
      particlesConfig: {
        color: "#dedede", // 粒子颜色
        particleOpacity: 0.7, // 粒子透明度
        particlesNumber: 80, // 粒子数量
        shapeType: "circle", // 可用的粒子外观类型有："circle","edge","triangle", "polygon","star"
        particleSize: 4, // 单个粒子大小
        linesColor: "#dedede", // 线条颜色
        linesWidth: 1, // 线条宽度
        lineLinked: true, // 连接线是否可用
        lineOpacity: 0.4, // 线条透明度
        linesDistance: 150, // 线条距离
        moveSpeed: 3, // 粒子运动速度
        hoverEffect: true, // 是否有hover特效
        clickEffect: true, // 是否有click特效
        hoverMode: "grab", // 可用的hover模式有: "grab", "repulse", "bubble"
        clickMode: "push", // 可用的click模式有: "push", "remove", "repulse", "bubble"
      }, // 背景插件配置
      headerSlogan: {
        title: "实时可扩展图分析平台",
        memo: "利用原生并行图技术释放互联数据的力量，洞悉深层关联的商业价值",
      }, // 头部slogan
      headerTitles: [
        {
          name: "产品技术",
          memo: "金融知识图谱",
        },
        {
          name: "行业方案",
          memo: "基于知识图谱的反欺诈系统",
        },
        {
          name: "最新动态",
          memo: "广州瑞友助力广东财经大学虚拟仿真实验教学",
        },
      ], // 头部标题
      productData: {
        name: "知识图谱平台",
        enName: "Knowledge Map Platform",
        proType: "graph",
        imgSrc: "https://s1.ax1x.com/2020/07/27/aPrAPI.png",
        memos: [
          '通过机器学习平台和智能决策平台，把传统的"单据式"数据全部联通，以知识图谱形式展示各种关联关系，如：历史项目和企业的图谱关联关系',
          "系统还会根据智能算法展示历史类似项目及供应商的关联关系,让系统各功能模板间的据互联,持续深化的帮助企业实现简单化、高效化、透明化以及人性化的体验。",
        ],
      }, // 产品数据
      productDetail: {
        style: {
          background:
            'url("https://s1.ax1x.com/2020/07/27/aPrPVH.png") no-repeat',
          "background-size": "cover",
        },
        productDetailList: [
          {
            icon: "/icons/icon_sj.png",
            name: "多维数据关系",
            memo:
              "知识图谱形式展示历史采购项目-供应商项目情况,项目及供应商的概况一目了然。",
          },
          {
            icon: "/icons/icon_yyx.png",
            name: "操作易用性",
            memo:
              "采购项目、供应商主要信息在图谱里可直接查看、复制、下载,方便快捷,省去很多机械性操作",
          },
          {
            icon: "/icons/icon_gk.png",
            name: "统一管控",
            memo:
              "知识图谱采用基于机构、节点、数据的权限控制,灵活实现集团统一管控",
          },
          {
            icon: "/icons/icon_qt.png",
            name: "其它特点",
            memo:
              "除采购项目、供应商以知识图谱形式展示外,还有项目档案图谱、供应商画像、合同画像、报表图谱等。",
          },
        ], // 产品细节数据
      }, // 产品细节
      application: {
        style: {
          background:
            'url("https://s1.ax1x.com/2020/07/27/aPDh80.png") no-repeat',
          "background-position": "bottom right",
        },
        applicationList: [
          {
            icon: "/icons/icon_cx.png",
            name: "基于图谱的检索查询",
            memo:
              "基于历史数据的精确、完整的查询,通过、无行业属性,基本无业务附加值。",
          },
          {
            icon: "/icons/icon_zn.png",
            name: "商业智能BI",
            memo: "基于图谱关系提高决策质量,行业属性凸显,业务附加值高。",
          },
          {
            icon: "/icons/icon_hx.png",
            name: "构建用户画像",
            memo: "基于用户画像实现精准营销,行业属性明显,业务附加值较高。",
          },
          {
            icon: "/icons/icon_znh.png",
            name: "智能化决策",
            memo: "基于图谱的智能推荐、智能决策,行业属性较强,解决业务痛点。",
          },
        ], // 产品应用数据
      }, // 产品应用
      news: {
        style: {
          background: "#f7f8f8",
        },
        newsList: [],
      }, // 新闻动态
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      let res = await getNewsList({ page: 0, size: 3, sort: "updatedAt,desc" });
      this.news.newsList = res.data?._embedded?.news;
    }, // 获取新闻列表
    handleScroll: Throttle(function() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.$store.commit("changeSticky", scrollTop >= 70);
    }, 200), // 屏幕滚动-防抖
    viewDetails(item) {
      let id = item._links.self.href.split("/").pop();
      this.$router.push(`/news/view/${id}`);
    }, // 查看新闻详情
    viewProduct(data) {
      console.log(data);
      this.$router.push(`/prodAndServer/${data.proType}`);
    }, // 查看产品详情
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.handleScroll, false);
  },
  beforeDestroy() {
    // 销毁滚动事件
    window.removeEventListener("scroll", this.handleScroll, false);
  },
};
</script>

<style lang="less" scoped>
// 主页-头部
.home-header {
  position: relative;
  height: 730px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // 头部-背景
  .header-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to right,
      #080d2a,
      #0c0f48,
      #271955,
      #0c0f48,
      #080d2a
    );
  }

  // 头部-logo
  .header-logo {
    margin: 110px 0 73px;
    height: 80px;
    z-index: 1;
  }

  // 头部-按钮
  .header-btn {
    z-index: 1;
    width: 180px;
    margin-top: 60px;
    border-color: var(--header-red);
    background-color: var(--header-red);
  }

  // 头部-标题
  .header-title {
    position: absolute;
    bottom: 0;
    height: 145px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);

    .title-items {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-wrap: wrap;

      .title-item {
        float: left;
        width: 380px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title-name {
          color: var(--header-red);
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 20px;
          border-bottom: 2px solid var(--header-red);
        }

        .title-memo {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}

// 主页-内容
.home-content {
  // 主体-新闻动态
  .news-items {
    width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  // 主体-联系我们
  .apply-contact {
    width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
}
</style>
