<!-- 账户登录表单 -->
<template>
  <div>
    <p class="form-title">帐号登录</p>

    <el-form :model="accountForm" :rules="rules" ref="accountForm">
      <el-form-item prop="username">
        <el-input
          ref="accountInput"
          v-model="accountForm.username"
          placeholder="手机号"
          autocomplete="on"
        ></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          v-model="accountForm.password"
          placeholder="密码"
          show-password
        ></el-input>
      </el-form-item>

      <drag-verify
        :isPassing.sync="isPassing"
        ref="dragVerify"
        text="请按住滑块拖动"
        successText="验证通过"
        @passcallback="$emit('dragPass')"
      >
      </drag-verify>
    </el-form>
  </div>
</template>

<script>
import { checkMobile } from "@/api/system";

export default {
  name: "accountForm",
  props: {
    accountForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    var mobilePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        checkMobile(this.accountForm.username)
          .then((res) => {
            res.data.state == "WAITING"
              ? callback(new Error("手机号审核中"))
              : callback();
          })
          .catch((err) => {
            err.response.status == 404
              ? callback(new Error("手机号不存在"))
              : callback();
          });
      }
    }; // 确认手机号存在

    return {
      isPassing: false, // 是否通过验证
      rules: {
        username: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
          },
          {
            validator: mobilePass,
            trigger: "none",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
      }, // 校验规则
    };
  },
  mounted() {
    this.$refs.accountInput.focus(); // 自动聚焦
  },
};
</script>

<style lang="less" scoped>
.form-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}
</style>
