<template>
  <!-- 新闻卡片组件 -->
  <div class="news-item trans_scale" @click="$emit('viewDetail')">
    <img :src="newsData.picture" alt="新闻配图" class="item-img" />
    <div class="item-content">
      <p class="item-title" v-text="newsData.title" />
      <p class="item-memo ellipses_3" v-text="newsData.contentPreview" />
      <div class="item-footer">
        <img src="/icons/RF_LOGO_black.png" alt="logo" class="item-logo" />
        <span class="item-time">{{ newsData.updatedAt | dateFormat }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsCard",
  props: {
    newsData: {
      type: Object,
      default() {
        return {
          picture: "新闻配图",
          title: "新闻标题",
          contentPreview: "新闻简介",
          updatedAt: "时间",
        };
      },
    }, // 产品数据
  },
};
</script>

<style lang="less" scoped>
.news-item {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 410px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

  .item-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .item-content {
    flex: 1;
    position: relative;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: justify;

    .item-title {
      font-size: 16px;
      min-height: 48px;
      line-height: 24px;
      color: var(--main-title_black);
    }

    .item-memo {
      font-size: 14px;
      line-height: 18px;
      margin-top: 20px;
      color: var(--main-memo_gray);
    }

    .item-footer {
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
      line-height: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .item-logo {
        height: 20px;
      }

      .item-time {
        font-size: 14px;
        color: var(--sub-title_gray);
      }
    }
  }
}
</style>
