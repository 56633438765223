<template>
  <div class="layout-header" :class="{ isSticky, hasHeight }">
    <!-- Logo -->
    <router-link to="/index" class="header-logo" v-if="isSticky">
      <img src="/icons/RF_LOGO_black.png" alt="logo" />
    </router-link>

    <!-- 菜单 -->
    <div class="header-menu">
      <!-- 菜单项 -->
      <div class="menu-item" v-for="(item, index) in headerMenus" :key="index">
        <!-- 菜单类型为0,链接类型 -->
        <template v-if="item.menuType === 0">
          <router-link :to="item.path" class="menu-nav" v-text="item.name" />
        </template>

        <!-- 菜单类型为1,带简单子菜单类型 -->
        <template v-else-if="item.menuType === 1">
          <!-- 菜单名称 -->
          <span class="menu-nav"
            >{{ item.name }} <span class="el-icon-caret-bottom" />
          </span>

          <!-- 菜单列表 -->
          <ul class="menu1-navs">
            <li
              class="nav1-item"
              v-for="(nav, index) in item.children"
              :key="index"
            >
              <router-link :to="nav.path" v-text="nav.name" />
            </li>
          </ul>
        </template>

        <!-- 菜单类型为2,带复杂子菜单类型 -->
        <template v-else>
          <!-- 菜单名称 -->
          <span class="menu-nav"
            >{{ item.name }} <span class="el-icon-caret-bottom" />
          </span>

          <!-- 菜单列表 -->
          <div class="menu-child-items" v-if="item.menuType === 2">
            <div
              class="items-list"
              v-for="(nav, navIdx) of item.children"
              :key="navIdx"
            >
              <p class="nav-title" v-text="nav.name" />
              <ul class="nav-list">
                <li
                  class="nav-item"
                  v-for="(navItem, navItemIdx) of nav.children"
                  :key="navItemIdx"
                >
                  <router-link :to="navItem.path" v-text="navItem.name" />
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>

      <!-- 已登录 -->
      <div class="menu-item isLogin" v-if="isLogin">
        <span>
          <i class="el-icon-user-solid"></i>
          {{ userInfo.name }}
        </span>
        <ul class="menu1-navs">
          <li class="nav1-item">
            <a @click.prevent="logout">退出登录</a>
          </li>
        </ul>
      </div>

      <!-- 登录/注册 -->
      <div class="loginReg" v-else>
        <a @click.prevent="toLogin">登录</a>
        <span class="vertical-line">丨</span>
        <router-link to="/register">注册</router-link>
      </div>
    </div>

    <!-- 登录dialog -->
    <login-dialog v-if="showLogin" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loginDialog from "@/components/local/login-dialog"; // 登录弹出框

export default {
  components: {
    loginDialog,
  },
  data() {
    return {};
  },
  created() {
    console.log(this);
  },
  computed: {
    hasHeight() {
      let notIndex = this.$route.path !== "/index";
      return notIndex;
    }, // 是否具有固定高度 (非主页时,有高度和Sticky定位;主页仅定位)
    ...mapState([
      "isSticky",
      "headerMenus",
      "showLogin",
      "isLogin",
      "userInfo",
    ]), // isSticky定位 headerMenus头部菜单 showLogin显示登录
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(path) {
        this.$store.commit("changeSticky", path !== "/index");
      },
    }, // 非主页直接加Sticky
  },
  methods: {
    toLogin() {
      this.$store.commit("changeShowLogin", true);
    }, // 登录
    logout() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("logout");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    }, // 退出登录
  },
};
</script>

<style lang="less" scoped>
@header-height: 70px; // 头部高度
@border-color: #e50113; // 颜色-红色

// 通常情况:
.layout-header {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  z-index: 999;

  .header-logo {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 30px;
    z-index: 1;

    img {
      display: block;
      height: 50px;
    }
  }

  .header-menu {
    width: 100%;
    color: #fff;
    display: flex;
    // transition: padding 0.3s;
    justify-content: center;
    height: @header-height;
    line-height: @header-height;
    background-color: transparent;

    // 子菜单
    .menu-item {
      position: relative;
      display: flex;
      margin: 0 20px;

      // 菜单标题
      .menu-nav {
        height: 100%;
        color: inherit;
        font-size: 14px;
        cursor: pointer;
        box-sizing: border-box;

        // 高亮当前路由
        &:hover,
        &.router-link-active {
          border-bottom: 2px solid @border-color;
        }
      }

      // 菜单子项(菜单类型2)
      .menu-child-items {
        position: absolute;
        top: @header-height;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px 20px;
        background-color: #fff;
        display: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .items-list {
          margin: 0 40px;
          text-align: left;
          font-size: 14px;
          white-space: nowrap;

          .nav-title {
            line-height: 46px;
            color: var(--main-title_black);
          }

          .nav-list {
            color: #696969;
            line-height: 34px;
            border-top: 1px solid #e4e4e4;

            .nav-item {
              a.router-link-active {
                color: var(--header-red);
              }
            }

            .nav-item:hover {
              color: var(--header-red);
            }
          }
        }
      }

      // 菜单子项(菜单类型1)
      .menu1-navs {
        position: absolute;
        top: @header-height;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px;
        background-color: #fff;
        display: none;
        color: var(--main-title_black);
        white-space: nowrap;
        flex-direction: column;
        line-height: 36px;
        font-size: 14px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .nav1-item {
          a.router-link-active {
            color: var(--header-red);
          }
        }
      }
    }

    // hover展示子菜单
    .menu-item:hover {
      .menu-child-items,
      .menu1-navs {
        display: flex;
      }
    }

    // 登录/注册
    .loginReg {
      display: flex;
      font-size: 14px;
      margin-left: 30px;

      .vertical-line {
        margin: 0 10px;
      }

      a:hover,
      a.router-link-active {
        border-bottom: 2px solid @border-color;
      }
    }
  }
}

// 滚动一定距离或非主页时
.layout-header.isSticky {
  position: sticky;

  &.hasHeight {
    height: 70px;
  }

  .header-menu {
    padding-right: 30px;
    justify-content: flex-end;
    color: var(--main-title-black);
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    // animation: opacity0To1 0.5s;
    // background: #fff url(/icons/RF_LOGO_black.png) no-repeat;
    // background-size: auto 50px;
    // background-position: 40px center;
  }
}

// @keyframes opacity0To1 {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
</style>
