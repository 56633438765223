<template>
  <!-- hlxz-组件 -->
  <div class="downloadHoriz">
    <div class="dlHorzi-content">
      <div class="content-left">
        <img src="/icons/icon_PDF.png" alt="" class="pdf-icon" />
        <div class="pdf-texts">
          <p class="pdf-title" v-text="fileName" />
          <p class="pdf-memo" v-text="memo" />
        </div>
      </div>

      <el-button type="primary" @click="$emit('download')" class="down-btn"
        >立即下载 <i class="el-icon-caret-right"
      /></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadHoriz",
  props: {
    fileName: {
      type: String,
      default: "文件名",
    },
    memo: {
      type: String,
      default: "想了解更多，请点击右方按钮来下载获取",
    },
  },
};
</script>

<style lang="less" scoped>
.downloadHoriz {
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("https://s1.ax1x.com/2020/07/27/aPDXP1.png") no-repeat;

  // 背景透明
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(42, 57, 80, 0.85);
  }

  .dlHorzi-content {
    position: relative;
    z-index: 1;
    width: 1200px;
    padding: 0 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-left {
      display: flex;

      .pdf-texts {
        display: flex;
        flex-direction: column;
        color: #fff;
        text-align: left;
        margin-left: 20px;
        line-height: 32px;

        .pdf-title {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .down-btn {
      width: 180px;
    }
  }
}
</style>
