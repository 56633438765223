<template>
  <div class="layout-footer">
    <div class="footer-container">
      <div class="footer-content">
        <!-- 底部导航 -->
        <footer-nav :navsData="footNavs" />

        <!-- 公司信息 -->
        <div class="footer-company">
          <p class="name">广州瑞友信息技术有限公司</p>
          <p class="enName">
            Guangzhou realfinance information technology co, LTD
          </p>
          <p>电话: 020-32285850</p>
          <p>QQ: 781878169</p>
          <p>邮箱: service@realfinance.com.cn</p>
          <p>地点: 广州市天河区建工路4号PCI未来社区</p>
        </div>
      </div>
    </div>

    <!-- 版权信息 -->
    <p class="footer-copyright">
      CopyRight©2017 广州瑞友信息技术有限公司 版权所有丨<a
        href="http://beian.miit.gov.cn/"
        >粤ICP备17022900号</a
      >
    </p>
  </div>
</template>

<script>
import footerNav from "./components/footer-navs";

export default {
  components: {
    footerNav,
  },
  data() {
    return {};
  },
  computed: {
    footNavs() {
      return this.$store.getters.footNavs;
    }, // 底部导航
  },
};
</script>

<style lang="less" scoped>
.layout-footer {
  background: #000 url("https://s1.ax1x.com/2020/07/27/aPD5vT.png") no-repeat
    center -15%;

  .footer-container {
    min-height: 270px;
    background-color: rgba(45, 45, 45, 0.9);

    .footer-content {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      padding: 40px 30px 27px;
      justify-content: space-between;

      .footer-company {
        color: #fff;
        text-align: left;

        p {
          font-size: 14px;
          line-height: 28px;
        }

        .name {
          font-size: 18px;
          line-height: 24px;
        }

        .enName {
          font-size: 12px;
          line-height: 1;
          margin-bottom: 24px;
        }
      }
    }
  }

  .footer-copyright {
    line-height: 80px;
    color: #fff;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.95);

    a:hover {
      color: red;
    }
  }
}
</style>
