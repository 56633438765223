<template>
  <!-- 产品底部-tabs组件 -->
  <div class="product-tabs">
    <!-- nav栏 -->
    <ul class="tab-navs">
      <li class="tab-nav" :class="{ active: currentIndex == index }" v-for="(item, index) of tabs" :key="index" v-text="item.navTitle" @click="currentIndex = index" />
    </ul>

    <!-- 内容栏 -->
    <div class="tab-items">
      <div class="tab-item" :class="{ active: currentIndex == index }" v-for="(item, index) of tabs" :key="index">
        <template>
          <!-- 多行模式 -->
          <ul class="item-ul" v-if="Array.isArray(item.content)">
            <li v-for="(content, cIdx) of item.content" :key="cIdx">
              <p v-if="content.title" class="li-title" v-text="content.title" />
              <p class="li-memo" v-text="content.memo" />
            </li>
          </ul>

          <!-- 单行模式 -->
          <p v-else class="item-content" v-text="item.content" />
        </template>

        <img :src="item.imgSrc" alt="产品配图" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productTabs",
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentIndex: 0, // 当前活跃下标
    };
  },
  watch: {
    "$route.params"() {
      this.currentIndex = 0;
    }, // 切换产品,tab还原
  },
};
</script>

<style lang="less" scoped>
.product-tabs {
  width: 1200px;
  margin: 40px auto 0;
  color: #000;
  border: 1px solid #ebebeb;

  .tab-navs {
    display: flex;
    height: 50px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ebebeb;

    .tab-nav {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 20px;
      transition: transform, color 0.3s;

      &.active,
      &:hover {
        color: var(--blue);
        transform: scale(1.1);
        border: 1px solid #ebebeb;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .tab-items {
    position: relative;
    // height: 370px;

    .tab-item {
      // position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 40px 60px;
      display: none;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      font-size: 14px;
      color: var(--main-memo_gray);

      &.active {
        display: flex;
      }

      img {
        width: 380px;
        height: 214px;
      }

      .item-ul {
        height: 100%;
        padding-right: 60px;
        text-align: justify;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        li {
          .li-title {
            font-size: 16px;
            margin-bottom: 6px;
            color: var(--main-title_black);
          }

          .li-memo {
            line-height: 24px;
            white-space: break-spaces;
          }
        }
        li + li {
          margin-top: 30px;
        }
      }

      .item-content {
        padding-right: 60px;
        line-height: 24px;
        text-align: justify;
      }
    }
  }
}
</style>
