<template>
  <!-- 产品介绍-组件 -->
  <div class="detail-items">
    <div
      class="detail-item trans_scale"
      :class="{ 'detail-item_3': detailsNum == 3 }"
      v-for="(item, index) in productDetailData"
      :key="index"
    >
      <img :src="item.icon" alt="图标" class="item-icon" />
      <p class="item-name" v-text="item.name" />
      <p class="item-memo" v-text="item.memo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "productDetail",
  props: {
    productDetailData: {
      type: Array,
      default() {
        return [
          {
            icon: "图标",
            name: "介绍名称",
            memo: "介绍备注",
          },
        ];
      },
    }, // 产品细节数据
    detailsNum: {
      type: Number,
      default: 4,
    }, // 产品细节数量
  },
};
</script>

<style lang="less" scoped>
.detail-items {
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .detail-item {
    position: relative;
    width: 262px;
    min-height: 320px;
    color: #000;
    margin: 15px;
    padding: 30px 15px;
    border-radius: 6px;
    line-height: 24px;
    transition: all 0.3s;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

    &.detail-item_3 {
      width: 360px;
      padding: 30px 60px;
      min-height: 262px;

      .item-memo {
        position: initial;
        margin-top: 16px;
      }
    }

    .item-icon {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }

    .item-name {
      font-size: 16px;
      margin-top: 20px;
      color: var(--main-title_black);
    }

    .item-memo {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 195px;
      font-size: 14px;
      color: var(--main-memo_gray);
    }
  }
}
</style>
