// 新闻接口

// 获取所有新闻
export function getNews() {
  return axios({
    url: `/api/news/search/findByState`,
    method: "get",
    params: {
      state: "PUBLISHED",
    },
  });
}

// 获取分页新闻列表
export function getNewsList(params) {
  return axios({
    url: `/api/news`,
    method: "get",
    params,
  });
}

// 根据id获取新闻
export function getNewsById(id) {
  return axios({
    url: `/api/news/${id}`,
    method: "get",
  });
}

// 根据类型获取新闻
export function getNewsByType(type) {
  return axios({
    url: `/api/news/search/findByTypeAndState`,
    method: "get",
    params: {
      type,
      state: "PUBLISHED",
    },
  });
}

// 根据id获取相邻新闻(前)
export function getPrev(id) {
  return axios({
    url: `/api/news/search/findPrevious`,
    method: "get",
    params: {
      id,
    },
  });
}

// 根据id获取相邻新闻(后)
export function getNext(id) {
  return axios({
    url: `/api/news/search/findNext`,
    method: "get",
    params: {
      id,
    },
  });
}
