import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import layout from "@/views/layout";
import home from "@/views/home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    component: layout,
    children: [
      {
        path: "/", // 首页
        name: "home",
        component: home,
        // redirect: "/prodAndServer/graph",
      },
      {
        path: "/prodAndServer/:id", // 产品与服务(由于未来可能改成后台添加,所以选择params形式)
        name: "prodAndServer",
        component: () => import("@/views/prodAndServer"),
      },
      {
        path: "/dataCenter", // 资料中心
        name: "dataCenter",
        component: () => import("@/views/dataCenter"),
      },
      {
        path: "/news", // 新闻动态
        component: () => import("@/views/news"),
        children: [
          {
            path: "/", // 新闻动态-默认
            name: "newsDefault",
            component: () => import("@/views/news/newsDefault"),
          },
          {
            path: "view/:id", // 新闻动态-详情
            name: "newsDetail",
            component: () => import("@/views/news/newsDetail"),
          },
        ],
      },
      {
        path: "/partners", // 合作伙伴
        name: "partners",
        component: () => import("@/views/partners"),
      },
      {
        path: "/applyTrial", // 申请试用
        name: "applyTrial",
        component: () => import("@/views/applyTrial"),
      },
      {
        path: "/aboutUs", // 关于我们
        name: "aboutUs",
        component: () => import("@/views/aboutUs"),
      },
      {
        path: "/joinUs", // 加入我们
        name: "joinUs",
        component: () => import("@/views/joinUs"),
      },
      {
        path: "/forgetPwd", // 忘记密码
        name: "forgetPwd",
        component: () => import("@/views/forgetPwd"),
      },
      {
        path: "/register", // 注册
        name: "register",
        component: () => import("@/views/register"),
      },
    ],
  },
  {
    path: "*", // 通配处理
    redirect: "/index",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  window.scrollTo(0, 0);
  store.commit("changeShowLogin", false); // 关闭登录弹窗
  next();
});

router.afterEach(() => {
  NProgress.done();
});

router.onError((err) => {
  NProgress.done();
});

export default router;
