import Qs from "qs";
// 系统接口

// 获取验证码
export function getVerifyCode(form) {
  let { mobile, type } = form;
  return axios({
    url: `/api/mobile-captcha/`,
    method: "post",
    data: {
      mobile: `+86${mobile}`,
      type,
    },
  });
}

// 确认验证码
export function checkVerifyCode(form) {
  let { mobile, type, captcha } = form;
  return axios({
    url: `/api/mobile-captcha/`,
    method: "get",
    params: {
      mobile: `+86${mobile}`,
      type,
      captcha,
    },
  });
}

// 注册
export function register(form) {
  return axios({
    url: `/api/user-registration-forms`,
    method: "post",
    data: {
      ...form,
      mobile: "+86" + form.mobile,
    },
  });
}

// 检查手机号是否注册
export function checkMobile(mobile) {
  return axios({
    url: `/api/user-registration-forms/search/check`,
    method: "get",
    params: {
      mobile: `+86${mobile}`,
    },
  });
}

// 验证码登录/注册
export function loginByMobile(form) {
  let { mobile, type, captcha } = form;
  return axios({
    url: `/mobile-login`,
    method: "post",
    params: {
      mobile: `+86${mobile}`,
      type,
      captcha,
    },
  });
}

// 密码登录
export function loginByUserName(form) {
  let { username, password } = form;
  let data = Qs.stringify({
    username: `+86${username}`,
    password,
  });
  return axios({
    url: `/login`,
    method: "post",
    data,
  });
}

// 忘记密码
export function resetPass(form) {
  return axios({
    url: `/api/forgot-password`,
    method: "post",
    data: {
      mobile: `+86${form.mobile}`,
      captcha: form.code,
      password: form.password,
    },
  });
}

// 退出登录
export function logout() {
  return axios({
    url: `/logout`,
    method: "post",
  });
}

// 获取用户信息
export function getUserInfo(name) {
  return axios({
    url: `/api/users/${name}`,
    method: "get",
  });
}
