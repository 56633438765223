import Vue from "vue";
// 获取所有的公共组件
const requireComponents = require.context("./", true, /\.vue/);
// console.log(requireComponents);

requireComponents.keys().forEach(fileName => {
  // 获取组件实例
  let componentInstance = requireComponents(fileName);
  // console.log(componentInstance);

  // console.log(componentInstance.default.name, componentInstance.default);
  Vue.component(componentInstance.default.name, componentInstance.default);
});
