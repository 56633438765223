<template>
  <!-- 动态列表-组件 -->
  <div class="productTable">
    <el-table
      ref="tableRef"
      :data="tableData"
      :size="size"
      border
      stripe
      style="width: 100%"
      v-loading="tableLoading"
      :max-height="maxHeight"
      :highlight-current-row="highlightCurrentRow"
      v-bind="otherElTableProps"
      @row-click="rowClick"
      @select="select"
      @select-all="selectAll"
    >
      <el-table-column
        v-if="!hideIndex"
        label="序号"
        type="index"
        width="55"
        align="center"
        fixed
      ></el-table-column>
      <!-- 动态列前插槽 -->
      <slot name="left"></slot>
      <template v-if="columnOption && columnOption.length > 0">
        <template v-for="o in columnOption">
          <el-table-column
            v-if="!o.hide"
            :key="o.key"
            :label="o.name"
            :prop="o.key"
            :min-width="o.minW"
            :align="o.align || 'center'"
            :fixed="o.fixed"
            :show-overflow-tooltip="o.overflow"
            :formatter="handleFormat(o)"
          ></el-table-column>
        </template>
      </template>
      <!-- 动态列后插槽 -->
      <slot name="right"></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "productTable",
  props: {
    tableData: {
      // 表格数据
      type: Array,
      default() {
        return [];
      },
    },
    columnOption: {
      // 动态列参数
      type: Array,
      // default() {
      //   return [
      //     {
      //       key: "valid", // 字段键名
      //       name: "是否生效", // 表头名
      //       minW: "100", // 最小宽度
      //       align: "center", // 对齐方向 'left' 'right' 'center'
      //       fixed: false, // 固定列 'left' 'right' false
      //       hide: false, // 是否隐藏
      //       overflow: false, // 过长省略
      //       enumMap: new Map([
      //         [true, "是"],
      //         [false, "否"],
      //       ]), // 枚举值 [value, label]
      //       formatFun: (row, column, cellValue, index) => {
      //         // 格式化回调函数 优先于enumMap
      //         // row:数据行对象  column:列对象(column.property可取当前列prop值)  cellValue:当前行列对应值  index:当前行索引
      //       },
      //     },
      //   ];
      // },
    },
    tableLoading: {
      // 表格加载状态
      type: Boolean,
      default: false,
    },
    hideIndex: {
      // 表格是否隐藏序号
      type: Boolean,
      default: false,
    },
    highlightCurrentRow: {
      // 选择行高亮
      type: Boolean,
      default: false,
    },
    maxHeight: {
      // 表格最大高度
      type: [String, Number],
      default: 500,
    },
    size: {
      // 表格大小
      type: String,
    },
    otherElTableProps: {
      // 其他el-table参数
      type: Object,
    },
  },
  methods: {
    getElTable() {
      return this.$refs.tableRef;
    },
    rowClick(row, column, event) {
      // 行点击
      this.$emit("row-click", row, column, event);
    },
    select(selection, row) {
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      this.$emit("select", selection, row);
    },
    selectAll(selection) {
      // 当用户手动勾选全选 Checkbox 时触发的事件
      this.$emit("select-all", selection);
    },
    clearSelection() {
      // 用于多选表格，清空用户的选择
      this.$refs.tableRef?.clearSelection();
    },
    toggleRowSelection(row, selected) {
      // 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
      this.$refs.tableRef?.toggleRowSelection(row, selected);
    },
    handleFormat(opt) {
      return (row, column, cellValue, index) => {
        if (opt.formatFun?.constructor === Function) {
          // 如果传入formatFun 则优先返回该回调函数
          return opt.formatFun(row, column, cellValue, index);
        }
        if (opt.enumMap?.constructor === Map) {
          // 如果传入enum 返回枚举值
          return opt.enumMap.get(cellValue);
        }
        // 默认返回当前行键值
        return cellValue;
      };
    },
  },
};
</script>
