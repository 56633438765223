/**
 * 函数防抖 (只执行最后一次点击)
 */
export function Debounce(fn, t) {
  let delay = t || 500;
  let timer;
  return function() {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
}

/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this;
    let args = arguments;

    if (timeout) clearTimeout(timeout);
    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
}

/*
 * 函数节流 (单位时间内只执行一次)
 */
export function Throttle(fn, t) {
  let last;
  let timer;
  let interval = t || 500;
  return function() {
    let args = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
}

/**
 * @description: 检查数据类型
 * @param {*} data
 * @returns: 数据类型
 *
 */

export function checkType(data) {
  var toString = Object.prototype.toString;
  var dataType =
    data instanceof Element
      ? "element" // 为了统一DOM节点类型输出
      : toString
          .call(data)
          .replace(/\[object\s(.+)\]/, "$1")
          .toLowerCase();
  return dataType;
}

/**
 * @description: 数组对象去重-forEach版
 * @param {Array} array 待去重数组
 * @param {String} attr 去重的对照属性
 * @returns: 去重后的数组
 */
export function arr_for_delmore(array, attr) {
  var tempArr = [];
  var obj = {};
  array.forEach((v, i) => {
    if (!obj.hasOwnProperty([array[i][attr]])) {
      tempArr.push(v);
      obj[array[i][attr]] = true;
    }
  });
  return tempArr;
}
/**
 * @description: 数组对象去重-(多个属性)
 * @param {*} array
 * @param {*} attr1
 * @param {*} attr2
 * @param {*} attr3
 * @param {*} attr4
 */
export function arr_for_delmoreAttr(array, attr1, attr2, attr3, attr4) {
  var tempArr = [];
  var obj = {};
  array.forEach((v, i) => {
    if (
      !obj[
        array[i][attr1] + "|" + array[i][attr2] + "|" + array[i][attr3][attr4]
      ]
    ) {
      tempArr.push(v);
      obj[
        array[i][attr1] + "|" + array[i][attr2] + "|" + array[i][attr3][attr4]
      ] = true;
    }
  });
  return tempArr;
}

/**
 * @description: 日期时间格式化
 * @param {String} time
 */
export function dateFormat(time) {
  const d = new Date(time);

  const year = d.getFullYear(); // 年
  const month = d.getMonth() + 1; // 月
  const day = d.getDate(); // 日

  const hh = d.getHours(); // 时
  const mm = d.getMinutes(); // 分
  const ss = d.getSeconds(); // 秒

  let clock = year + "-";

  if (month < 10) clock += "0";

  clock += month + "-";

  if (day < 10) clock += "0";

  clock += day + " ";

  if (hh < 10) clock += "0";

  clock += hh + ":";
  if (mm < 10) clock += "0";
  clock += mm + ":";

  if (ss < 10) clock += "0";
  clock += ss;
  return clock;
}
