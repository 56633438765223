<template>
  <!-- 申请-联系我们-组件 -->
  <div class="apply-contact-item">
    <p class="ac-title" v-text="title" />
    <p class="ac-memo" v-text="memo" />
    <el-button
      :type="plain ? '' : 'primary'"
      class="ac-btn"
      :plain="plain"
      :class="{ plain }"
      @click="$emit('acBtn')"
      >{{ btnText }}</el-button
    >
  </div>
</template>

<script>
export default {
  name: "applyContact",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    memo: {
      type: String,
      default: "备注",
    },
    btnText: {
      type: String,
      default: "按钮",
    },
    plain: {
      type: Boolean,
      default: false,
    }, // 镂空按钮
  },
};
</script>

<style lang="less" scoped>
.apply-contact-item {
  text-align: left;
  margin-right: 130px;
  .ac-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--main-title_black);
  }

  .ac-memo {
    width: 266px;
    margin: 30px 0;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: var(--main-memo_gray);
  }

  .ac-btn {
    width: 120px;
    &.plain {
      border-color: var(--button-color_blue);
      color: var(--button-color_blue);
    }
  }
}
</style>
