<template>
  <div class="layout">
    <!-- 头部 -->
    <layout-header />

    <!-- 主体 -->
    <div class="layout-center">
      <!-- <transition name="el-fade-in-linear" appear mode="out-in"> -->
      <router-view />
      <!-- </transition> -->
    </div>

    <!-- 底部 -->
    <layout-footer />
  </div>
</template>

<script>
import layoutHeader from "./header";
import layoutFooter from "./footer";

export default {
  components: {
    layoutHeader,
    layoutFooter,
  },
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.layout-center {
  flex: 1;
}
</style>
