<template>
  <!-- 产品顶部简介-组件 -->
  <div
    class="productBrief"
    :style="`backgroundImage: url(${imgSrc});height:${height}px`"
  >
    <p class="brief-title" v-text="title" />
    <p
      class="brief-content"
      v-for="(item, index) in memo"
      :key="index"
      v-text="item"
    />
  </div>
</template>

<script>
export default {
  name: "productBrief",
  props: {
    height: {
      type: [String, Number],
      default: 450,
    },
    title: {
      type: String,
      default: "标题",
    },
    memo: {
      type: Array,
      default() {
        return ["简介内容"];
      },
    },
    imgSrc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.productBrief {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: skyblue;
  background-repeat: no-repeat;
  background-position: center;

  .brief-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 70px;
    margin-bottom: 10px;
  }

  .brief-content {
    width: 640px;
    font-size: 20px;
    text-align: center;
    line-height: 44px;
  }
}
</style>
