"use strict";
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import { Message, MessageBox } from "element-ui";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.NODE_ENV == "production" ? "" : "/api",
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error && error.response) {
      // console.log({ error });
      switch (error.response.status) {
        case 400:
          Message.error("错误请求");
          break;
        case 401:
          if (error.response.data) {
            Message.error(error.response.data);
          } else {
            Message.error("登录过期，请重新登录");
          }
          // MessageBox.confirm("此操作将跳转登录, 是否继续?", "提示", {
          //   confirmButtonText: "确定",
          //   cancelButtonText: "留在此页",
          //   type: "warning",
          // })
          //   .then(() => {
          //     location.reload();
          //   })
          //   .catch(() => {});
          break;
        case 403:
          Message.error("拒绝访问");
          break;
        case 404:
          // Message.error("请求错误,未找到该资源");
          break;
        case 405:
          Message.error("请求方法未允许");
          break;
        case 408:
          Message.error("请求超时");
          break;
        case 500:
          Message.error("服务器端出错");
          break;
        case 501:
          Message.error("网络未实现");
          break;
        case 502:
          Message.error("网络错误");
          break;
        case 503:
          Message.error("服务不可用");
          break;
        case 504:
          Message.error("网络超时");
          break;
        case 505:
          Message.error("http版本不支持该请求");
          break;
        default:
          Message.error(`连接错误${error.response.status}`);
      }
    } else {
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时，请刷新当前页");
      }
      Message.error("连接服务器失败");
    }

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
