<template>
  <!-- slogan-组件 -->
  <div class="slogan">
    <p v-text="title" />
    <p v-text="memo" />
  </div>
</template>

<script>
export default {
  name: "slogan",
  props: {
    title: {
      type: String,
      default: "标题"
    },
    memo: {
      type: String,
      default: "备注"
    }
  }
};
</script>

<style lang="less" scoped>
// slogan
.slogan {
  z-index: 1;

  p {
    color: #fff;
    font-size: 18px;

    &:first-child {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
}
</style>
