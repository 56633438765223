<template>
  <svg :class="{ icon: true, spin }" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "svgIcon",
  props: {
    spin: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`;
    },
  },
};
</script>

<style lang="less">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  &.spin {
    animation: rotating 2s linear infinite;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
