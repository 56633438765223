import Vue from "vue";
import "@/plugins/axios";
import App from "./App.vue";
import router from "./router";
import { dateFormat } from "@/utils/index.js"; // 引入工具函数

// ElementUI
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);

// AntvUI
import { Progress } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Progress);

// 背景插件
import VueParticles from "vue-particles";
Vue.use(VueParticles);

import "@/assets/css/commen.css"; // 公共样式
import "@/assets/font/iconfont.css"; // 字体图标
import "@/assets/icons/iconfont.js"; // svg字体图标
import "@/components/global/autoImport.js"; // 自动引入全局组件

Vue.filter("dateFormat", dateFormat); // 日期格式化

import store from "./store"; // 引入Vuex

Vue.config.productionTip = false;

(function() {
  // 设置meta视口(用于pc端页面,在移动端正常缩放)
  let scale = 1 / window.devicePixelRatio;
  if (scale < 1) {
    scale = 0.3;
  }
  document
    .querySelector('meta[name="viewport"]')
    .setAttribute(
      "content",
      `width=device-width,initial-scale=${scale},minimum-scale=${scale},maximum-scale=${scale},user-scalable=no`
    );
})();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
