import Vue from "vue";
import Vuex from "vuex";
import {
  loginByMobile,
  loginByUserName,
  getUserInfo,
  logout,
} from "@/api/system";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: sessionStorage.getItem("isLogin") == "true" ? true : false, // 登录状态
    isSticky: false, // 顶部是否sticky定位
    showLogin: false, // 是否显示登录弹窗
    headerMenus: [
      {
        name: "首页",
        enName: "index",
        menuType: 0,
        footNavs: false,
        path: "/index",
      },
      {
        name: "产品与服务",
        enName: "products and services",
        menuType: 2,
        footNavs: true,
        children: [
          {
            name: "智慧银行",
            children: [
              { name: "新一代管理会计", path: "/prodAndServer/MACC" },
              { name: "采购管理系统", path: "/prodAndServer/purchMng" },
            ],
          },
          {
            name: "智慧教育",
            children: [
              { name: "金融仿真实验教学系统(FVR)", path: "/prodAndServer/FVR" },
            ],
          },
          {
            name: "AI应用",
            children: [
              { name: "金融知识图谱", path: "/prodAndServer/graph" },
              { name: "智能对话机器人", path: "/prodAndServer/robot" },
              { name: "大数据平台", path: "/prodAndServer/bigData" },
            ],
          },
          // {
          //   name: "服务资料",
          //   children: [
          //     { name: "智慧金融", path: "/dataCenter" },
          //     { name: "智慧金融教育", path: "/dataCenter" },
          //     { name: "金融大数据平台解决方案", path: "/dataCenter" },
          //   ],
          // },
        ],
      },
      {
        name: "资料中心",
        enName: "data center",
        menuType: 0,
        footNavs: true,
        path: "/dataCenter",
        children: [
          { name: "产品概览与案例", path: "/dataCenter" },
          { name: "应用与解决方案概览", path: "/dataCenter" },
          { name: "白皮书", path: "/dataCenter" },
        ],
      },
      {
        name: "新闻动态",
        enName: "news",
        menuType: 0,
        footNavs: false,
        path: "/news",
      },
      {
        name: "合作伙伴",
        enName: "partners",
        menuType: 0,
        footNavs: true,
        path: "/partners",
        children: [
          { name: "合作流程", path: "/partners" },
          { name: "渠道代理", path: "/partners" },
        ],
      },
      {
        name: "申请试用",
        enName: "applyTrial",
        menuType: 0,
        footNavs: false,
        path: "/applyTrial",
      },
      {
        name: "关于我们",
        enName: "about us",
        path: "/aboutUs",
        menuType: 1,
        footNavs: true,
        children: [
          { name: "加入瑞友", path: "/joinUs" },
          { name: "关于瑞友", path: "/aboutUs" },
        ],
      },
    ], // 菜单
    userInfo: sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {}, // 用户信息
  },
  getters: {
    footNavs(state) {
      let tempNavs = JSON.parse(JSON.stringify(state.headerMenus));
      return tempNavs.filter((item) => {
        if (item.footNavs) {
          if (item.menuType === 2) {
            let tempArr = [];
            item.children.forEach((nav) => {
              tempArr = [...tempArr, ...nav.children];
            });
            item.children = tempArr;
          }
          return item;
        }
      });
    }, // 底部导航
  },
  mutations: {
    changeIsLogin(state, value) {
      state.isLogin = value;
      sessionStorage.setItem("isLogin", value);
    }, // 改变登录状态
    changeUserInfo(state, value) {
      state.userInfo = value;
      sessionStorage.setItem("userInfo", JSON.stringify(value));
    }, // 改变用户信息
    changeSticky(state, value) {
      state.isSticky = value;
    }, // 改变顶部定位模式
    changeShowLogin(state, value) {
      state.showLogin = value;
    }, // 切换显示登录
  },
  actions: {
    loginByMobile({ commit }, formData) {
      loginByMobile(formData)
        .then((res) => {
          commit("changeIsLogin", true); // 登录状态
          commit("changeShowLogin", false); // 关闭登录框

          getUserInfo(res.data.name).then((info) => {
            commit("changeUserInfo", info.data); // 保存用户信息
          });
        })
        .catch((err) => {
          commit("changeIsLogin", false); // 未登录状态
        });
    }, // 手机号登录
    loginByUserName({ commit, state }, formData) {
      loginByUserName(formData)
        .then((res) => {
          commit("changeIsLogin", true); // 登录状态
          commit("changeShowLogin", false); // 关闭登录框

          getUserInfo(res.data.name).then((info) => {
            commit("changeUserInfo", info.data); // 保存用户信息
          });
        })
        .catch((err) => {
          commit("changeIsLogin", false); // 未登录状态
        });
    }, // 用户名登录
    logout({ commit }) {
      logout()
        .then((res) => {
          commit("changeIsLogin", false); // 清空登录状态
          commit("changeUserInfo", {}); // 清空用户信息
        })
        .catch((err) => {});
    }, // 退出登录
  },
  modules: {},
});
