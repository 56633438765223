<template>
  <!-- 横栏-组件 -->
  <div class="horizontal-bar" :style="barStyle" :class="{ grayTitle, extraBg }">
    <template v-if="!hiddenTitle">
      <p class="bar-title" v-text="title" />
      <p class="bar-subTitle" v-text="subTitle" />
    </template>

    <slot />
  </div>
</template>

<script>
export default {
  name: "horizontalBar",
  props: {
    title: {
      type: String,
      default: "主标题",
    },

    subTitle: {
      type: String,
      default: "副标题",
    },

    barStyle: {
      type: Object,
      default() {
        return {};
      },
    }, // 样式

    grayTitle: {
      type: Boolean,
      default: false,
    }, // 是否灰色标题

    extraBg: {
      type: Boolean,
      default: false,
    }, // 是否透明背景

    hiddenTitle: {
      type: Boolean,
      default: false,
    }, // 隐藏标题
  },
};
</script>

<style lang="less" scoped>
.horizontal-bar {
  // min-height: 470px;
  position: relative;
  color: #fff;
  padding: 60px 0;
  box-sizing: border-box;

  .bar-title {
    position: relative;
    z-index: 1;
    font-size: 24px;
  }

  .bar-subTitle {
    position: relative;
    z-index: 1;
    font-size: 14px;
    margin-top: 10px;
  }

  &.grayTitle {
    .bar-title {
      color: #000;
    }
    .bar-subTitle {
      color: var(--sub-title_gray);
    }
  }

  &.extraBg::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(22, 95, 162, 0.85);
  }
}
</style>
