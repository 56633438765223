<template>
  <!-- 产品应用组件-类似一条新闻 -->
  <div class="application-items">
    <div
      class="application-item"
      v-for="(item, index) in applicationData"
      :key="index"
    >
      <img :src="item.icon" alt="图标" class="item-icon" />
      <div class="item-info">
        <p class="info-title" v-text="item.name"></p>
        <p class="info-memo" v-text="item.memo"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productApplication",
  props: {
    applicationData: {
      type: Array,
      default() {
        return [
          {
            icon: "图标",
            name: "应用名称",
            memo: "应用备注",
          },
        ];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.application-items {
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .application-item {
    display: flex;
    height: 50px;
    margin-bottom: 60px;
    align-items: center;

    .item-icon {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    .item-info {
      text-align: left;
      margin-left: 20px;

      .info-title {
        font-size: 16px;
        color: var(--main-title_black);
        margin-bottom: 16px;
      }

      .info-memo {
        font-size: 14px;
        color: var(--main-memo_gray);
      }
    }
  }

  .application-item:last-child {
    margin-bottom: 0;
  }
}
</style>
