<template>
  <!-- 登录弹框-组件 -->
  <div class="login-dialog">
    <div class="login-form" @keydown.esc="closeLogin">
      <!-- 关闭按钮 -->
      <i class="el-icon-close close-btn" @click="closeLogin" />

      <!-- 短信登录表单 -->
      <loginPhone
        ref="loginPhone"
        v-show="loginType == 'phone'"
        :phoneForm="phoneForm"
      />

      <!-- 账户登录表单 -->
      <loginAccount
        ref="loginAccount"
        v-show="loginType == 'account'"
        :accountForm="accountForm"
        @dragPass="dragPass = true"
      />

      <!-- 登录底部 -->
      <div class="form-footer">
        <div class="form-forget">
          <router-link to="/forgetPwd">忘记帐号密码</router-link>
          <el-checkbox v-model="isRemember">记住登录名</el-checkbox>
        </div>

        <el-button
          v-if="loginType == 'phone'"
          type="primary"
          @click="loginByMobile"
          >登录</el-button
        >
        <el-button
          v-else
          type="primary"
          :disabled="!dragPass"
          @click="loginByUserName"
          >登录</el-button
        >

        <div class="login-btn">
          <a v-if="loginType == 'phone'" @click="toggleLoginType('account')"
            >账号登录</a
          >
          <a v-else @click="toggleLoginType('phone')">手机号登录</a>
        </div>

        <p class="reg-btn">
          尚未拥有账户?
          <router-link to="/register">注册</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import loginPhone from "./login-phone";
import loginAccount from "./login-account";

export default {
  name: "loginDialog",
  components: {
    loginPhone,
    loginAccount,
  },
  data() {
    return {
      loginType: "phone", // 登录方式
      phoneForm: {}, // 手机号表单
      accountForm: {}, // 账户表单
      dragPass: false, // 账户滑动验证通过
      isRemember: true, // 是否记住用户名
    };
  },
  methods: {
    closeLogin() {
      this.$store.commit("changeShowLogin", false);
    }, // 关闭登录
    toggleLoginType(type) {
      this.loginType = type;
    }, // 切换登录类型
    loginByMobile() {
      this.$refs.loginPhone.$refs.phoneForm.validate((err) => {
        if (err) {
          this.$store.dispatch("loginByMobile", {
            ...this.phoneForm,
            captcha: this.phoneForm.code,
            type: "SIGN_IN",
          });
        }
      });
    }, // 手机号登录
    loginByUserName() {
      this.$refs.loginAccount.$refs.accountForm.validate((err) => {
        if (err) {
          this.$store.dispatch("loginByUserName", this.accountForm);
        }
      });
    }, // 用户名登录
  },
};
</script>

<style lang="less" scoped>
.login-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    position: relative;
    width: 400px;
    min-height: 450px;
    border-radius: 10px;
    padding: 50px 40px 40px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 16px;
      color: var(--main-memo_gray);
    }

    .form-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .form-footer {
      padding-top: 15px;

      .form-forget {
        display: flex;
        justify-content: space-between;
      }

      .el-button {
        width: 100%;
        margin: 20px 0;
        font-size: 16px;
      }

      .login-btn {
        font-weight: 450;
        color: var(--button-color_blue);
      }

      .reg-btn {
        margin-top: 20px;

        a {
          font-weight: 450;
          color: var(--button-color_blue);
        }
      }
    }
  }
}
</style>
