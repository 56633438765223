<!-- 短信登录表单 -->
<template>
  <div>
    <p class="form-title">短信登录</p>

    <el-form :model="phoneForm" :rules="rules" ref="phoneForm">
      <el-form-item prop="mobile">
        <el-input
          ref="phoneInput"
          v-model="phoneForm.mobile"
          placeholder="手机号"
          autocomplete="on"
        ></el-input>
      </el-form-item>

      <el-form-item prop="code">
        <div class="getCode">
          <el-input
            v-model="phoneForm.code"
            placeholder="短信验证码"
          ></el-input>
          <el-button
            plain
            :disabled="codeSet.waitSend"
            @click="sendCode"
            style="min-width:130px"
            >{{ codeSet.btnText }}</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getVerifyCode, checkMobile } from "@/api/system";

export default {
  name: "phoneForm",
  props: {
    phoneForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    var mobilePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        checkMobile(this.phoneForm.mobile)
          .then((res) => {
            res.data.state == "WAITING"
              ? callback(new Error("手机号审核中"))
              : callback();
          })
          .catch((err) => {
            err.response.status == 404
              ? callback(new Error("手机号不存在"))
              : callback();
          });
      }
    }; // 确认手机号存在

    return {
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
          {
            validator: mobilePass,
            trigger: "none",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
        account: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      }, // 校验规则
      codeSet: {
        waitSend: false, // 等待再次发送
        btnText: "发送验证码",
      }, // 验证码设置
    };
  },
  mounted() {
    this.$refs.phoneInput.focus(); // 自动聚焦
  },
  methods: {
    sendCode() {
      this.codeSet.waitSend = true;
      this.$refs.phoneForm.validateField("mobile", (err) => {
        if (!err) {
          let num = 60;
          let timer = setInterval(() => {
            if (num >= 1) {
              this.codeSet.btnText = `重新发送(${num--}s)`;
            } else {
              clearInterval(timer);
              this.codeSet.waitSend = false;
              this.codeSet.btnText = `发送验证码`;
            }
          }, 1000);

          getVerifyCode({
            mobile: this.phoneForm.mobile,
            type: "SIGN_IN",
          });
        } else {
          this.codeSet.waitSend = false;
        }
      });
    }, // 发送验证码
  },
};
</script>

<style lang="less" scoped>
.form-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}

.getCode {
  display: flex;

  button {
    margin-left: 10px;
    border-color: var(--button-color_blue);
    color: var(--button-color_blue);

    &.is-disabled {
      min-width: 130px;
      border-color: #dcdfe6;
      color: var(--sub-title_gray);
    }
  }
}
</style>
