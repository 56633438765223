<template>
  <!-- 底部导航-组件 -->
  <div class="footer-navs">
    <div class="nav-item" v-for="(item, index) of navsData" :key="index">
      <div class="item-header">
        <p class="header-title" v-text="item.enName[0]" />
        <div class="header-info">
          <p class="header-name" v-text="item.name" />
          <p class="header-enName" v-text="item.enName.slice(1)" />
        </div>
      </div>

      <ul class="item-childs">
        <li
          v-for="(link, linkIdx) of item.children"
          :key="linkIdx"
          class="child-name"
        >
          <router-link :to="link.path">{{ link.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerNavs",
  props: {
    navsData: {
      type: Array,
      default() {
        return [
          {
            name: "菜单名",
            enName: "menu-name",
            children: [{ name: "导航名", to: "/" }],
          },
        ];
      },
    }, // 导航数据
  },
};
</script>

<style lang="less" scoped>
.footer-navs {
  display: flex;

  .nav-item {
    color: #fff;
    margin-right: 75px;
    text-align: left;

    .item-header {
      display: flex;

      .header-title {
        font-size: 36px;
        text-transform: uppercase;
      }
      .header-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 6px;

        .header-name {
          font-size: 14px;
        }
      }
    }

    .item-childs {
      margin-top: 25px;

      .child-name {
        font-size: 14px;
        line-height: 24px;

        a {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
</style>
