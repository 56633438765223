<template>
  <!-- 产品信息概述组件 -->
  <div class="product">
    <div class="product-item">
      <img class="product-img" :src="productData.imgSrc" alt="产品图" />
      <div class="product-info">
        <p class="info-name" v-text="productData.name" />
        <p class="info-en_name" v-text="productData.enName" />

        <div>
          <!-- 带点格式 -->
          <ul class="info-list" v-if="dot">
            <li
              class="info-memo dot"
              v-for="(memo, index) in productData.memos"
              :key="index"
            >
              <span v-text="memo" />
            </li>
          </ul>

          <p
            v-else
            class="info-memo"
            v-for="(memo, index) in productData.memos"
            :key="index"
            v-text="memo"
          />
        </div>

        <el-button
          v-if="showBtn"
          plain
          class="info-btn"
          @click="$emit('viewProduct', productData)"
          >查看详情 <i class="el-icon-caret-right"
        /></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  props: {
    productData: {
      type: Object,
      default() {
        return {
          imgSrc: "产品图",
          name: "产品名称",
          enName: "产品英文名称",
          memo1: "产品备注1",
          memo2: "产品备注2",
        };
      },
    }, // 产品数据
    showBtn: {
      type: Boolean,
      default: false,
    }, // 显示按钮
    dot: {
      type: Boolean,
      default: false,
    }, // 点格式
  },
};
</script>

<style lang="less" scoped>
.product {
  // height: 440px;
  height: 420px;
  padding: 60px 0;
  box-sizing: border-box;

  .product-item {
    margin: 0 auto;
    display: flex;
    width: 1200px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;

    .product-img {
      height: 100%;
      // max-height: 100%;
      // max-width: 460px;
      max-width: 500px;
    }

    .product-info {
      width: 555px;
      height: 100%;
      text-align: left;

      .info-name {
        color: #000;
        font-size: 24px;
      }

      .info-en_name {
        color: var(--sub-title_gray);
        margin: 10px 0 40px;
      }

      .info-list {
        transform: translate(20px);
      }

      .info-memo {
        color: #595959;
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
        margin-bottom: 40px;

        &.dot {
          margin-bottom: 0;
          line-height: 28px;
          list-style: outside;
          color: var(--header-red);

          span {
            color: var(--main-memo_gray);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .info-btn {
        width: 180px;
        margin-top: 40px;
        border-color: #409eff;
        color: #409eff;
      }
    }
  }
}
</style>
